export const system_api = {
  delay: 500,
  timeout: 1000 * 60 * 30,
};

export const system_toast = {
  life: 5000,
};

export const system_dropdown = {
  empty: "無資料",
  emptyFilter: "查無資料",
};

export const system_table = {
  empty: "無資料",
  size: 10,
};
