import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setBackLayout, setToast } from "../../redux/slice/system";
import { setUser } from "../../redux/slice/user";
import { Outlet, useNavigate } from "react-router-dom";
import moment from "moment";

import BackSidebar from "./sidebar";
import BackTopbar from "./topbar";

import LayoutCss from "../../styles/components/layout.module.scss";

export default function BackLayoutMain(props) {
  const system = useSelector((state) => state.system);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // 螢幕大小設定
  const [screen, setScreen] = useState({
    width: null,
    height: null,
  });

  // 偵測是否有登入資料，沒有則導回登入頁
  useEffect(() => {
    let user_local = localStorage.getItem("user");
    if (!user.userDetail && !user_local) {
      navigate("/login");
    }
  }, []);

  // 偵測螢幕大小
  useEffect(() => {
    const resize = () => {
      setScreen({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", resize);

    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  // 依照螢幕大小控制 sidebar
  useEffect(() => {
    if (!screen.width || !screen.height) {
      return;
    }

    if (screen.width <= 768) {
      dispatch(
        setBackLayout({
          isSideShow: false,
          isSideFixed: false,
        })
      );
    } else {
      dispatch(
        setBackLayout({
          isSideFixed: true,
        })
      );
    }
  }, [screen]);

  // useEffect(() => {
  //   let timer = setInterval(() => {
  //     if (user.userDetail) {
  //       let diff = moment().diff(user.userDetail?.now);

  //       if (diff >= 3 * 60 * 60 * 1000 || !user.userDetail?.now) {
  //         dispatch(
  //           setToast({
  //             severity: "error",
  //             summary: "超過使用時限，請重新登入",
  //             detail: "",
  //           })
  //         );
  //         dispatch(setUser(null));
  //         navigate("/login");
  //       }
  //     } else {
  //       clearInterval(timer);
  //     }
  //   }, 5000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [user.userDetail]);

  return (
    <>
      <div
        className={`${LayoutCss["back-layout-wrap"]} ${
          system.backLayout.isSideFixed &&
          `${LayoutCss["layout-sidebar-fixed"]}`
        } ${
          system.backLayout.isSideShow && `${LayoutCss["layout-sidebar-show"]}`
        }`}
      >
        {/* 上方區塊 */}
        <BackTopbar LayoutCss={LayoutCss} />

        <div className={`${LayoutCss["layout-body"]}`}>
          {/* 左側區塊 */}
          <BackSidebar LayoutCss={LayoutCss} />

          <div className={`${LayoutCss["layout-body-content"]}`}>
            {/* 內容區塊，依照 route 顯示不同元件 */}
            {user.userDetail && <Outlet />}
          </div>
        </div>
      </div>
    </>
  );
}
