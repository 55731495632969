import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { setUser } from "../../redux/slice/user";
import { EmployeeAPI } from "../../axios/api";
import { useAbortedEffect } from "../../components/hooks";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { PressureAPI } from "../../axios/api";
import moment from "moment";
import * as XLSXSTYLE from "xlsx-js-style";

import {
  FormInputtext,
  FormInputnumber,
  FormInputtextarea,
  FormDropdown,
  FormCheckbox,
} from "../../components/formElement/index";
import { saveExcel, datenum } from "../../service/excel";
import { option_api_method } from "../../service/option";

import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function Pressure(props) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const crumbItem = [{ label: "壓力測試" }];
  // 資料
  const [tbData, setTbData] = useState([]);
  // confirm order line
  const [confirmChecked, setConfirmChecked] = useState(false);
  // 呼叫 API 總量
  const [apiCounts, setApiCounts] = useState(0);
  const [apiResult, setApiResult] = useState([]);
  // 表單設定
  const defaultValues = {
    checkAll: false,
    domain: "",
    token: "",
    data: [],
  };
  const schema = yup
    .object({
      domain: yup.string().required("必填"),
      data: yup.array().of(
        yup.object().shape({
          method: yup
            .string()
            .test("required", "必填", (value, { from: [{ value: data }] }) => {
              return data.check_flag ? (value ? true : false) : true;
            })
            .nullable(),
          sub_domain: yup
            .string()
            .test("required", "必填", (value, { from: [{ value: data }] }) => {
              return data.check_flag ? (value ? true : false) : true;
            }),
          counts: yup
            .number()
            .test("required", "必填", (value, { from: [{ value: data }] }) => {
              return data.check_flag ? (value ? true : false) : true;
            })
            .nullable(),
        })
      ),
    })
    .required();
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    // let invalid = submitData.data.some(
    //   (item) => !item.sub_domain || !item.counts
    // );
    // if (invalid) {
    //   setToast({
    //     severity: "warn",
    //     summary: "請確認填寫 url、執行次數",
    //     detail: "",
    //   });
    //   return;
    // }

    dispatch(setProgress(true));

    dispatch(
      setUser({
        ...user.userDetail,
        access_token: submitData.token,
      })
    );

    let tempResult = [];

    submitData.data.forEach((item, index) => {
      tempResult.push([]);

      let count = 0;
      while (count < item.counts) {
        if (item.check_flag) {
          let request = null,
            path = `${submitData.domain}${item.sub_domain}`;

          if (item.method === "get") {
            request = PressureAPI.emptyGet({
              path: path,
              // data: item.parameter,
            }).then((result) => {
              let { Response, status, time, message } = result;

              setApiCounts((state) => state + 1);

              if (Response === 1) {
                return [status, time];
              } else {
                return [status, time];
              }
            });
          } else if (item.method === "post") {
            request = PressureAPI.emptyPost({
              path: path,
              data: item.parameter,
            }).then((result) => {
              let { Response, status, time, message } = result;

              setApiCounts((state) => state + 1);

              if (Response === 1) {
                return [status, time];
              } else {
                return [status, time];
              }
            });
          }

          tempResult[index][count] = request;
          setApiResult(tempResult);
        }

        count++;
      }
    });
  };

  useEffect(() => {
    let submitData = getValues();
    let totalCount = submitData.data.reduce(
      (pre, cur) => pre + (cur.check_flag ? cur.counts : 0),
      0
    );

    if (totalCount !== 0 && totalCount === apiCounts) {
      setApiCounts(0);
      dispatch(setProgress(true));

      let temp = [];
      apiResult.forEach((items, index) => {
        if (!submitData.data[index].check_flag) return;

        let rows = [];
        items.forEach((item) => {
          item.then((result) => rows.push(result));
        });
        temp.push(rows);
      });

      setTimeout(() => {
        console.log(temp);

        exportFile(submitData, temp);
      }, 1000);
    }
  }, [apiCounts]);

  const sheet_from_array_of_arrays = (data, otherSum) => {
    let space = { c: 0, r: 0 }; // 若需要空行/列
    let ws: any = {};
    console.log(otherSum);
    ws[
      XLSXSTYLE.utils.encode_cell({
        c: 3,
        r: 0,
      })
    ] = {
      v: "Success",
      t: "s",
      s: {
        font: {
          sz: "12",
          color: { rgb: "ffffff" },
        },
        alignment: {
          horizontal: "left",
          vertical: "center",
          wrapText: true,
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
        fill: {
          fgColor: { rgb: "28a745" },
        },
      },
    };
    ws[
      XLSXSTYLE.utils.encode_cell({
        c: 4,
        r: 0,
      })
    ] = {
      v: otherSum[0] ?? 0,
      t: "n",
      s: {
        font: {
          sz: "12",
        },
        alignment: {
          horizontal: "left",
          vertical: "center",
          wrapText: true,
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
        fill: {
          fgColor: { rgb: "ffffff" },
        },
      },
    };
    ws[
      XLSXSTYLE.utils.encode_cell({
        c: 3,
        r: 1,
      })
    ] = {
      v: "Failed",
      t: "s",
      s: {
        font: {
          sz: "12",
          color: { rgb: "ffffff" },
        },
        alignment: {
          horizontal: "left",
          vertical: "center",
          wrapText: true,
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
        fill: {
          fgColor: { rgb: "dc3545" },
        },
      },
    };
    ws[
      XLSXSTYLE.utils.encode_cell({
        c: 4,
        r: 1,
      })
    ] = {
      v: otherSum[1] ?? 0,
      t: "n",
      s: {
        font: {
          sz: "12",
        },
        alignment: {
          horizontal: "left",
          vertical: "center",
          wrapText: true,
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
        fill: {
          fgColor: { rgb: "ffffff" },
        },
      },
    };

    for (let R = 0; R !== data.length; ++R) {
      for (let C = 0; C !== data[R].length; ++C) {
        let cell: any = {
          v: data[R][C] ?? "",
          s: {
            font: {
              sz: "12",
              color: { rgb: R === 0 ? "ffffff" : "000000" },
            },
            alignment: {
              horizontal: "left",
              vertical: "center",
              wrapText: true,
            },
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
            fill: {
              fgColor: {
                rgb: R === 0 ? "305496" : "ffffff",
              },
            },
          },
        };

        if (cell.v === null) continue;
        let cell_ref = XLSXSTYLE.utils.encode_cell({ c: C, r: R + space.r });

        if (typeof cell.v === "number") cell.t = "n";
        else if (typeof cell.v === "boolean") cell.t = "b";
        else if (cell.v instanceof Date) {
          cell.t = "n";
          cell.z = XLSXSTYLE.SSF._table[14];
          cell.v = datenum(cell.v, null);
        } else cell.t = "s";

        ws[cell_ref] = cell;
      }
    }

    let range = {
      s: { c: 0, r: 0 },
      e: { c: space.c + data[0].length + 3, r: space.r + data.length + 10 },
    };
    if (range.s.c < 10000000) ws["!ref"] = XLSXSTYLE.utils.encode_range(range);
    // ws["!merges"] = [
    //   // 合併欄位
    //   { s: { c: 4, r: 0 }, e: { c: 7, r: 0 } },
    // ];

    // 每行 寬度
    ws["!cols"] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    return ws;
  };

  // 匯出 Excel
  const exportFile = (submitData, result) => {
    let sheetNames = [],
      head = ["Status", "Times"],
      sum = [];
    result.forEach((item, index) => {
      let success = 0,
        failed = 0;
      item.forEach((rowItem) => {
        if (rowItem[0] >= 200 && rowItem[0] <= 299) {
          success++;
        } else {
          failed++;
        }
      });
      sum.push([success, failed]);

      let sub_domain = submitData.data[index].sub_domain;
      if (submitData.data[index].method === "get") {
        sub_domain = sub_domain.split("?")[0];
      } else if (submitData.data[index].method === "post") {
      }
      sub_domain = sub_domain.split("/");
      sub_domain = sub_domain[sub_domain.length - 1];
      sheetNames.push(sub_domain);
      item.unshift(head);
    });
    console.log(sheetNames);

    let ws = result.map((item, index) =>
      sheet_from_array_of_arrays(item, sum[index])
    );
    let setting = {
      filename: `report_${moment().format("YYYYMMDDHHmmss")}`,
      sheetNames: sheetNames,
      ws: ws,
    };
    saveExcel(setting, (errMsg) => {
      if (errMsg) {
        dispatch(
          setToast({
            severity: "error",
            summary: errMsg,
            detail: "",
          })
        );
      }
      dispatch(setProgress(false));
    });
  };

  const addAPI = () => {
    let submitData = getValues();
    let temp = [...submitData.data];
    temp.push({
      method: null,
      sub_domain: "",
      counts: null,
      parameter: "",
      check_flag: false,
    });

    setTbData(temp);
    reset({ ...submitData, checkAll: false, data: temp });
  };

  const removeAPI = ({ rowIndex }) => {
    let submitData = getValues();
    let temp = [...submitData.data];
    temp.splice(rowIndex, 1);

    setTbData(temp);
    reset({ ...submitData, data: temp });
  };

  // 勾選-切換
  const checkChange = (type, e) => {
    let submitData = getValues();
    if (!submitData.data || submitData.data.length === 0) return;

    let count = 0;
    if (type === "all") {
      submitData.data.forEach((item) => {
        item.check_flag = submitData.checkAll;

        if (item.check_flag) count++;
      });

      setTbData(submitData.data);
      reset(submitData);
    } else {
      submitData.data.forEach((item) => {
        if (item.check_flag) count++;
      });
      if (submitData.checkAll !== !(submitData.data.length !== count)) {
        submitData.checkAll = !(submitData.data.length !== count);

        setTbData(submitData.data);
        reset(submitData);
      }
    }
    setConfirmChecked(count > 0);
  };

  return (
    <>
      <BreadCrumb
        className="mb-3 border-none"
        model={crumbItem}
        home={{
          icon: "pi pi-home",
        }}
      />

      <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid">
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="domain"
            className={classNames(
              { "p-error": errors.domain },
              "col-2 text-right mb-0"
            )}
          >
            Domain
          </label>
          <div className="col-7 lg:col-5 md:col-8">
            <FormInputtext setting={setting} data={{ name: "domain" }} />
          </div>
          <div className="flex col-3 lg:col-5 md:col-2">
            <Button
              type="button"
              label="新增 API"
              className="ml-auto px-3"
              onClick={() => addAPI()}
            />
          </div>
        </div>
        <div className="flex align-items-center col-12">
          <label
            htmlFor="token"
            className={classNames(
              { "p-error": errors.token },
              "col-2 text-right mb-0"
            )}
          >
            Token
          </label>
          <div className="col-7 lg:col-5 md:col-8">
            <FormInputtext setting={setting} data={{ name: "token" }} />
          </div>
        </div>

        <div className="w-full border-bottom-1 border-400 my-3"></div>

        <div className="flex field px-3 col-12">
          <div className="flex align-items-center justify-content-end col-2 mb-0">
            <FormCheckbox
              className="m-0"
              setting={setting}
              data={{
                name: "checkAll",
                label: "",
                type: "boolean",
                value: true,
                changeFun: (e) => checkChange("all", e),
              }}
            />
          </div>
          <div className="flex align-items-center col-10">
            勾選執行
            <Button
              type="submit"
              label="執行 API"
              className="p-button-success ml-3 px-3"
              disabled={!confirmChecked}
            />
          </div>
        </div>

        {tbData.map((item, index) => (
          <div
            key={`item_${index}`}
            className={classNames("border-1 border-200 py-3 col-12", {
              "-m-t-1": index !== 0,
            })}
          >
            <div className="flex align-items-center field col-12">
              <label
                htmlFor="domain"
                className="flex align-items-center justify-content-end col-2 mb-0"
              >
                <div className="mr-3">API {index + 1}</div>
                <FormCheckbox
                  className="m-0"
                  setting={setting}
                  data={{
                    name: `data[${index}].check_flag`,
                    label: "",
                    type: "boolean",
                    value: true,
                    changeFun: (e) => checkChange("single", e),
                    // disabled: !(item.sub_domain && item.counts),
                  }}
                />
              </label>
              <div className="col-2 lg:col-2 md:col-2">
                <FormDropdown
                  setting={setting}
                  data={{
                    name: `data[${index}].method`,
                    options: option_api_method,
                    optionLabel: "name",
                    optionValue: "value",
                    placeholder: "method",
                  }}
                />
              </div>
              <div className="col-6 lg:col-5 md:col-6">
                <FormInputtext
                  setting={setting}
                  data={{
                    name: `data[${index}].sub_domain`,
                    placeholder: "url",
                  }}
                />
              </div>
              <div className="flex col-2 lg:col-2 md:col-2">
                <FormInputnumber
                  setting={setting}
                  data={{
                    name: `data[${index}].counts`,
                    placeholder: "執行次數",
                  }}
                />
                <Button
                  type="button"
                  icon="pi pi-trash"
                  className="p-button-danger p-button-icon-only ml-3 px-3"
                  onClick={() => removeAPI({ rowIndex: index })}
                />
              </div>
            </div>

            {getValues("data")[index].method === "post" && (
              <div
                key={`item_${index}`}
                className="flex align-items-center col-12"
              >
                <label
                  htmlFor="domain"
                  className="flex align-items-center justify-content-end col-2 mb-0"
                ></label>
                <div className="col-10 lg:col-9 md:col-10">
                  <FormInputtextarea
                    setting={setting}
                    data={{
                      name: `data[${index}].parameter`,
                      placeholder: "傳遞參數",
                    }}
                  />
                </div>
                <div className="flex col-3 lg:col-2 md:col-4"></div>
              </div>
            )}
          </div>
        ))}

        {/* <div className="field col-12 text-right">
          <Button type="submit" label="確認" className="ml-2 px-3" />
        </div> */}
      </form>
    </>
  );
}
