import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setBackLayout } from "../../redux/slice/system";

import { Sidebar } from "primereact/sidebar";
import { Menu } from "primereact/menu";

import img_logo from "../../images/logo.svg";

export default function BackSidebar(props) {
  let { LayoutCss } = props;
  const route_location = useLocation();
  const navigate = useNavigate();
  const system = useSelector((state) => state.system);
  const dispatch = useDispatch();
  // menu 項目資料
  const [menuItem, setMenuItem] = useState([]);

  // 設定 menu 項目
  useEffect(() => {
    let temp = [
      {
        label: "壓力測試",
        className: matchPath("/back/pressure"),
        command: (e) => {
          navigate("/back/pressure");
        },
      },
      // {
      //   label: "範例",
      //   className: matchPath("/back/employee"),
      //   command: (e) => {
      //     navigate("/back/employee");
      //   },
      // },
    ];

    setMenuItem(temp);
  }, [route_location.pathname]);

  const matchPath = (path) => {
    let { pathname } = route_location;

    return pathname.indexOf(path) !== -1 ? "menu-active" : "";
  };

  return (
    <>
      <div className={`${LayoutCss["layout-sidebar"]}`}>
        <Sidebar
          visible={system.backLayout.isSideShow}
          modal={!system.backLayout.isSideFixed}
          dismissable={!system.backLayout.isSideFixed}
          showCloseIcon={false}
          onHide={() =>
            dispatch(
              setBackLayout({
                isSideShow: false,
              })
            )
          }
        >
          {/* <div className={`${LayoutCss["layout-logo"]}`}>
            <img src={img_logo} />
          </div> */}
          <Menu className="w-full border-none" model={menuItem} />
        </Sidebar>
      </div>
    </>
  );
}
