import { useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setToast } from "./redux/slice/system";
import { setUser } from "./redux/slice/user";

import { system_toast } from "./service/system";

import BackLayoutMain from "./components/backLayout/main"; // 後台 - 介面外框
import Login from "./page/login"; // 登入頁
import Pressure from "./page/pressure";
import EmployeeList from "./page/employee/list"; // 範例-列表
import EmployeeDetail from "./page/employee/detail"; // 範例-明細

import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

export default function App() {
  const system = useSelector((state) => state.system);
  const dispatch = useDispatch();
  const toast = useRef(null);

  // 控制 toast 顯示內容
  useEffect(() => {
    if (!system.toast) {
      return;
    }

    toast.current.show({
      ...system.toast,
    });

    // toast 顯示結束後，將全域 toast 資料清除
    setTimeout(() => {
      dispatch(setToast(null));
    }, system.toast.life ?? system_toast.life);
  }, [system.toast]);

  // 偵測是否有 localStorage，有則寫入 store
  useEffect(() => {
    let user_local = localStorage.getItem("user");

    if (user_local) {
      user_local = JSON.parse(user_local);
      dispatch(setUser(user_local));
    }
  }, []);

  return (
    <>
      {system.isProgress && (
        <div
          className="fixed flex align-items-center justify-content-center w-full h-full bg-black-alpha-20"
          style={{ zIndex: 1199 }}
        >
          <ProgressSpinner />
        </div>
      )}

      <Toast ref={toast} />

      <CreatRoute />
    </>
  );
}

function CreatRoute() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Login />} />
          <Route path="/login" element={<Login />} />

          {/* 後台相關頁面 */}
          <Route path="/back" element={<BackLayoutMain />}>
            <Route path="" element={<EmployeeList />} />
            <Route path="pressure" element={<Pressure />} />
            <Route path="employee" element={<EmployeeList />} />
            <Route path="employee/:id" element={<EmployeeDetail />} />

            <Route path="*" element={<>無畫面</>} />
          </Route>

          <Route path="*" element={<>無畫面</>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
