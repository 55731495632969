import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";

export default function FormInputtextarea(props) {
  const {
    className,
    setting: { Controller, control, isEdit = true },
    data: {
      uiClassName,
      name,
      maxlength,
      placeholder = "",
      disabled = false,
      saveFun = () => {},
    },
  } = props;

  return (
    <>
      <Controller
        key={name}
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <div
            className={classNames(
              "flex align-items-center flex-auto",
              className
            )}
          >
            <InputTextarea
              id={field.name}
              className={classNames(
                { "p-invalid": fieldState.invalid },
                "w-full",
                uiClassName
              )}
              {...field}
              value={field.value ? field.value : ""}
              onBlur={saveFun}
              maxLength={maxlength}
              placeholder={placeholder}
              rows={5}
              cols={30}
              autoResize
              disabled={!isEdit || disabled}
            />
          </div>
        )}
      />
    </>
  );
}
