import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";

export default function FormCheckbox(props) {
  const {
    className,
    setting: {
      Controller,
      control,
      setValue,
      trigger,
      isSubmitted,
      isEdit = true,
    },
    data: {
      uiClassName,
      name,
      label,
      type = "multi",
      value,
      disabled = false,
      changeFun = () => {},
      saveFun = () => {},
    },
  } = props;

  const onChange = (e, field) => {
    const {
      checked,
      target: { name, value },
    } = e;

    if (type === "multi") {
      if (e.checked) field.value.push(e.value);
      else field.value.splice(field.value.indexOf(value), 1);
      setValue(name, field.value);
    } else if (type === "boolean") {
      setValue(name, checked);
    }

    // 因為是客製化的 function，設定欄位值後(setValue)，需要觸發(trigger) hookform，才會被驗證
    if (isSubmitted) {
      trigger();
    } else {
      trigger(name);
    }

    changeFun(e);
    saveFun();
  };

  return (
    <>
      <Controller
        key={name}
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <div
              className={classNames("field-radiobutton min-w-220", className)}
              style={{ margin: "0.5rem 0.5rem 0.5rem 0.25rem" }}
            >
              <Checkbox
                inputId={`${field.name}_${value}`}
                className={classNames(
                  { "p-invalid": fieldState.invalid },
                  uiClassName
                )}
                {...field}
                value={value}
                checked={
                  type === "multi"
                    ? field.value.indexOf(value) !== -1
                    : field.value
                }
                onChange={(e) => onChange(e, field)}
                disabled={!isEdit || disabled}
              />
              <label
                htmlFor={`${field.name}_${value}`}
                className="white-space-nowrap ml-1"
              >
                {label}
              </label>
            </div>
          </>
        )}
      />
    </>
  );
}
