import request from "../request";
import Url from "../url/pressure";

const emptyGet = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.empty + path, data, options);
};
const emptyPost = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.empty + path, data, options);
};

export default {
  emptyGet,
  emptyPost,
};
