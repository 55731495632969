import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
// import { EmployeeAPI } from "../../axios/api";
import { useAbortedEffect } from "../../components/hooks";
import moment from "moment";

import {
  formatOptions,
  getPageSetting,
  setPageSetting,
  parseExcelDateToDate,
} from "../../service/common";
import { readExcelFile, checkColumn } from "../../service/excel";
import { system_dropdown, system_table } from "../../service/system";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";

export default function EmployeeList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const crumbItem = [{ label: "員工資料" }];
  const [reload, setReload] = useState({ list: false });
  // 列表資料
  const [apiData, setApiData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [firstIndex, setFirstIndex] = useState(0);
  // 篩選條件
  const defaultFilter = {
    size: system_table.size,
    onedown_username: null,
    username: "",
  };
  const [filterData, setFilterData] = useState(defaultFilter);
  const [search, setSearch] = useState({});
  const [optionData, setOptionData] = useState({});
  const [iptKey, setIptKey] = useState(new Date());
  const [importIndex, setImportIndex] = useState(null);
  // 是否顯示彈出視窗
  const [isPop, setIsPop] = useState({
    manager: false,
  });
  // 彈出視窗資料
  const [popOption, setPopOption] = useState({
    type: "", // 開啟的pop
    data: {}, // 資料
  });

  // 表格頁數切換觸發
  const onPage = (e) => {
    const startIndex = e.first;

    // 同一頁面，不刷新
    if (startIndex === firstIndex) {
      return;
    }

    setFirstIndex(startIndex);
    setSearch((state) => ({
      ...state,
      page: Math.floor(startIndex / system_table.size) + 1,
    }));
  };

  useAbortedEffect((signal) => {
    // EmployeeAPI.getOneDownMemberList({ options: { signal } }).then((result) => {
    //   let { Response, data, message } = result;
    //   if (Response === 1 && data) {
    //     data = data.map((item) => ({
    //       ...item,
    //       combineLabel: `${item.name} (${item.username})`,
    //     }));
    //     let temp = formatOptions(
    //       {
    //         oneDown: data,
    //       },
    //       { uniqueKey: "id" }
    //     );
    //     setOptionData((state) => ({
    //       ...state,
    //       ...temp,
    //     }));
    //   } else {
    //     dispatch(
    //       setToast({
    //         severity: "error",
    //         summary: message,
    //         detail: "",
    //       })
    //     );
    //   }
    // });
  }, []);

  // 取 該頁搜尋參數
  useEffect(() => {
    let result = getPageSetting(["/employee"]);

    setFirstIndex(
      result && result.params.page
        ? (result.params.page - 1) * system_table.size
        : 0
    );
    if (result) setFilterData((state) => ({ ...state, ...result.params }));
    setSearch(result ? result.params : filterData);
  }, []);

  // 更新篩選條件
  const changeFilter = ({ target }) => {
    let { name, value } = target;

    setFilterData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  // 判斷是否搜尋
  useEffect(() => {
    let isSearch = Object.keys(search).length > 0;
    if (!isSearch) {
      return;
    }

    setReload((state) => ({ ...state, list: true }));
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      dispatch(setProgress(true));

      let params = {
        ...search,
      };

      setPageSetting({ params, page: "/employee" });

      // EmployeeAPI.getMemberList({ data: params, options: { signal } }).then(
      //   (result) => {
      //     let { Response, data, total, message } = result;

      //     if (Response === 1 && data) {
      //       setApiData(data);
      //       setTotalRecords(total);
      //       setReload((state) => ({
      //         ...state,
      //         list: false,
      //       }));
      //     } else {
      //       dispatch(
      //         setToast({
      //           severity: "error",
      //           summary: message,
      //           detail: "",
      //         })
      //       );
      //     }

      //     dispatch(setProgress(false));
      //   }
      // );
    },
    [reload]
  );

  const praseFile = (e) => {
    if (!e.target.files[0]) {
      dispatch(
        setToast({
          severity: "error",
          summary: "未選擇檔案",
          detail: "",
        })
      );
      return;
    }

    dispatch(setProgress(true));

    let file = e.target.files[0];

    let columns = [
      {
        username: "ID",
        actual_mgrid: "Actual mgr ID",
      },
      {
        username: "Person ID",
        name: "Full Name",
        cost_centre: "Cost Centre",
        department: "Department Description",
        global_business_function: "Global Business Function",
        business_function_lvl1: "Business Division",
        business_function_lvl2: "Business Section",
        business_function_lvl3: "Business Function L3",
        mgrid: "Supervisor",
        supervisor_name: "Supervisor Name",
        matrix_mgrid: "Matrix Manager",
        original_start_date: "Original Start Date",
        location: "Location Code",
        location_descr: "Location Description",
        email_address: "Business Email ID",
      },
    ];
    let column = columns[importIndex];

    readExcelFile(file, 0)
      .then(({ keys, data }) => {
        setIptKey(new Date());

        let checkValid = checkColumn(column, data[0] ?? {});

        if (data.length === 0) {
          dispatch(
            setToast({
              severity: "warn",
              summary: "無資料",
              detail: "",
            })
          );
          dispatch(setProgress(false));
          return;
        } else if (!checkValid.valid) {
          dispatch(
            setToast({
              severity: "error",
              summary: "匯入格式錯誤",
              detail: `請確認 Excel 檔案內容\n缺少欄位：${checkValid.errColumns.join(
                "、"
              )}`,
              sticky: true,
            })
          );
          dispatch(setProgress(false));
          return;
        }

        let temp = { filename: file.name, data: [] };
        data.forEach((item, index) => {
          if (importIndex === 0) {
            temp.data.push({
              username: item[column.username],
              actual_mgrid: item[column.actual_mgrid],
            });
          } else if (importIndex === 1) {
            temp.data.push({
              username: item[column.username],
              name: item[column.name],
              cost_centre: item[column.cost_centre],
              department: item[column.department],
              global_business_function: item[column.global_business_function],
              business_function_lvl1: item[column.business_function_lvl1],
              business_function_lvl2: item[column.business_function_lvl2],
              business_function_lvl3: item[column.business_function_lvl3],
              mgrid: item[column.mgrid],
              supervisor_name: item[column.supervisor_name],
              matrix_mgrid: item[column.matrix_mgrid] ?? "",
              original_start_date: item[column.original_start_date]
                ? moment(
                    parseExcelDateToDate(item[column.original_start_date])
                  ).format("YYYY")
                : "",
              location: item[column.location],
              location_descr: item[column.location_descr],
              email_address: item[column.email_address],
            });
          }
        });

        saveData(temp);
      })
      .catch((err) => console.log(err));
  };

  const saveData = (importData) => {
    // let promise;
    // if (importIndex === 0) {
    //   promise = EmployeeAPI.importUpdateActualMgrid({ data: importData });
    // } else if (importIndex === 1) {
    //   promise = EmployeeAPI.importMemebrData({ data: importData });
    // }
    // promise.then((result) => {
    //   let { Response, data, message } = result;
    //   if (Response === 1) {
    //     dispatch(
    //       setToast({
    //         severity: "success",
    //         summary: "匯入成功",
    //         detail: "",
    //       })
    //     );
    //     setReload((state) => ({
    //       ...state,
    //       list: true,
    //     }));
    //     if (importIndex === 0) {
    //       openPop({ type: "manager", data: data });
    //       dispatch(setProgress(false));
    //     }
    //   } else if (Response === 3) {
    //     dispatch(
    //       setToast({
    //         severity: "error",
    //         summary: "匯入失敗",
    //         detail: message,
    //         sticky: true,
    //       })
    //     );
    //     dispatch(setProgress(false));
    //   } else {
    //     dispatch(
    //       setToast({
    //         severity: "error",
    //         summary: message,
    //         detail: "",
    //       })
    //     );
    //     dispatch(setProgress(false));
    //   }
    // });
  };

  // 開啟 彈出視窗
  const openPop = (options) => {
    setPopOption(options);
    setIsPop({
      ...isPop,
      [options.type]: true,
    });
  };

  // 關閉 彈出視窗
  const closePop = (options) => {
    setIsPop((state) => ({
      ...state,
      [popOption.type]: false,
    }));

    if (options?.reload) {
      setReload((state) => ({
        ...state,
        list: true,
      }));
    }
  };

  return (
    <>
      <BreadCrumb
        className="mb-3 border-none"
        model={crumbItem}
        home={{
          icon: "pi pi-home",
        }}
      />

      <h2 className="my-4 ml-4">員工資料</h2>

      <div className="formgrid grid p-fluid w-full border-1 border-solid border-200 m-0 px-2 py-3">
        <div className="col-12 md:col-4 lg:col-3 flex align-items-start flex-column sm:flex-row sm:align-items-center mt-2 sm:mt-0">
          <label
            htmlFor="username"
            className="w-9rem white-space-nowrap text-left sm:text-right mr-2"
          >
            CEO one down
          </label>
          <Dropdown
            name="onedown_username"
            className="w-full md:w-auto"
            style={{ minWidth: "calc(100% - 9rem)" }}
            value={filterData.onedown_username}
            onChange={(e) => changeFilter(e)}
            options={optionData.oneDown}
            optionLabel="combineLabel"
            optionValue="username"
            filter
            filterBy="combineLabel"
            emptyFilterMessage={system_dropdown.emptyFilter}
            showClear
          />
        </div>
        <div className="col-12 md:col-4 lg:col-3 flex align-items-start flex-column sm:flex-row sm:align-items-center mt-2 md:mt-0">
          <label
            htmlFor="username"
            className="w-9rem white-space-nowrap text-left sm:text-right mr-2"
          >
            員工編號(名稱)
          </label>
          <InputText
            id="username"
            className="w-full"
            name="username"
            value={filterData.username}
            onChange={(e) => changeFilter(e)}
          />
        </div>
        <div className="col-12 md:col-4 lg:col-3 flex align-items-center mt-2 md:mt-0">
          <Button
            className="w-auto ml-auto md:ml-3 px-3"
            type="button"
            icon="pi pi-search"
            label="搜尋"
            onClick={() => {
              setSearch(filterData);
              setFirstIndex(0);
            }}
          />

          <Button
            className="p-button-secondary w-auto ml-3 px-3"
            type="button"
            icon="pi pi-undo"
            label="清除篩選"
            onClick={() => {
              setFilterData(defaultFilter);
              setSearch(defaultFilter);
            }}
          />
        </div>

        <div className="col-12 my-2 lg:hidden">
          <hr className="border-1 border-solid border-200 border-top-none border-x-none" />
        </div>

        <div className="col-12 lg:col-3 flex align-items-center">
          <Button
            type="button"
            label="實際主管更新匯入"
            icon="pi pi-sign-in"
            className="p-button-secondary p-button-outlined w-auto ml-auto px-2 py-1 rotate-icon"
            onClick={() => {
              setImportIndex(0);
              document.getElementById("file").click();
            }}
          />

          <Button
            type="button"
            label="全行名冊匯入"
            icon="pi pi-sign-in"
            className="p-button-secondary p-button-outlined w-auto ml-2 px-2 py-1 rotate-icon"
            onClick={() => {
              setImportIndex(1);
              document.getElementById("file").click();
            }}
          />

          <input
            key={iptKey}
            id="file"
            type="file"
            name="file"
            className="hidden"
            accept=".xlsx,.xls"
            onChange={(e) => praseFile(e)}
          />
        </div>
      </div>

      <DataTable
        value={apiData}
        className="mt-3"
        size="normal"
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        lazy
        first={firstIndex}
        totalRecords={totalRecords}
        onPage={onPage}
        rows={system_table.size}
        scrollable
        scrollDirection="both"
        emptyMessage={system_table.empty}
      >
        <Column
          field="username"
          header="員工編號"
          className="flex-grow-1"
          style={{ flexBasis: "100px" }}
        ></Column>
        <Column
          field="name"
          header="姓名"
          className="flex-grow-1"
          style={{ flexBasis: "100px" }}
        ></Column>
        <Column
          field="original_start_date"
          header="入職年份"
          className="flex-grow-1"
          style={{ flexBasis: "100px" }}
        ></Column>
        <Column
          field="department"
          header="部門"
          className="flex-grow-1"
          style={{ flexBasis: "100px" }}
        ></Column>
        <Column
          field="business_function_lvl1"
          header="Global Lv1 1"
          className="flex-grow-1"
          style={{ flexBasis: "150px" }}
        ></Column>
        <Column
          field="business_function_lvl2"
          header="Global Lv1 2"
          className="flex-grow-1"
          style={{ flexBasis: "150px" }}
        ></Column>
        <Column
          field="business_function_lvl3"
          header="Global Lv1 3"
          className="flex-grow-1"
          style={{ flexBasis: "150px" }}
        ></Column>

        <Column
          field="id"
          header="進階"
          className="w-6rem"
          alignFrozen="right"
          frozen
          body={(data) => (
            <>
              <Button
                className="p-button-primary p-button-sm p-button-text"
                type="button"
                label="編輯"
                onClick={() => navigate(`/back/employee/${data.username}`)}
              />
            </>
          )}
        ></Column>
      </DataTable>

      <Dialog
        header={
          <h2 className="text-center mt-3 mb-1">
            {popOption.type === "manager" ? "變更名單" : ""}
          </h2>
        }
        headerClassName="border-bottom-none"
        contentClassName="px-0"
        visible={isPop.manager}
        className="w-11 sm:w-6 max-w-35rem"
        closable={false}
      >
        {isPop.manager ? (
          <PopManager popOption={popOption} closePop={closePop} />
        ) : null}
      </Dialog>
    </>
  );
}

function PopManager(props) {
  const { popOption, closePop } = props;

  return (
    <>
      <div className="px-2">
        <div className="grid px-5">
          <DataTable
            value={popOption.data ?? []}
            className="w-full"
            // size="small"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            // lazy
            // first={firstIndex}
            // totalRecords={totalRecords}
            // onPage={onPage}
            rows={system_table.size}
            emptyMessage={system_table.empty}
          >
            <Column className="w-6" field="username" header="員工編號"></Column>
            <Column
              className="w-6"
              field="actual_mgrid"
              header="更新後的實際主管編號"
            ></Column>
          </DataTable>
        </div>
      </div>

      <div className="w-full text-right border-top-1 border-400 p-3 pb-0">
        <Button
          type="button"
          label="確認"
          className="ml-2 px-3"
          onClick={() => closePop()}
        />
      </div>
    </>
  );
}
