import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import * as XLSXSTYLE from "xlsx-js-style";

const readFile = (file: File) => {
  return new Promise((reslove) => {
    let reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e) => {
      reslove(e.target?.result);
    };
  });
};

async function readExcelFile(file: File, sheetIndex: number) {
  let parse = await readFile(file);
  let workbook = XLSX.read(parse, { type: "binary" });
  let worksheet = workbook.Sheets[workbook.SheetNames[sheetIndex]];
  let data: any[] = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
  let keys: string[] = [];

  if (data.length > 0) {
    keys = Object.keys(data[0]);
  }

  return {
    keys: keys,
    data: data,
  };
}

export function exportFile(type: any, data: any[]) {
  let fileName = `template.${type}`;

  switch (type) {
    case "xlsx":
      fileExcel();
      break;
    case "xls":
      fileExcel();
      break;
    case "csv":
      fileExcel();
      break;
    case "txt":
      fileTxt();
      break;
    default:
      break;
  }

  function fileExcel() {
    let workSheet = XLSX.utils.aoa_to_sheet(data); // [[],[],...]
    // let jsonWorkSheet = XLSX.utils.json_to_sheet(data); // [{},{},...]

    let workBook = {
      SheetNames: ["sheet1"],
      Sheets: {
        sheet1: workSheet,
      },
    };

    XLSX.writeFile(workBook, fileName);
  }

  function fileTxt() {
    let arrayWorkSheet = XLSX.utils.aoa_to_sheet(data);
    let txtOutput = XLSX.utils.sheet_to_csv(arrayWorkSheet);

    let textFileAsBlob = new Blob([txtOutput], { type: "text/plain" });
    let href = window.webkitURL.createObjectURL(textFileAsBlob);

    download(href);
  }

  function download(href: any) {
    var downloadLink = document.createElement("a");
    downloadLink.download = fileName;
    downloadLink.innerHTML = "Download File";
    downloadLink.href = href;
    downloadLink.onclick = (e: any) => {
      document.body.removeChild(e.target);
    };
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);

    downloadLink.click();
  }
}

function datenum(v: any, date1904: any) {
  if (date1904) v += 1462;
  var epoch = Date.parse(v);
  return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

function Workbook() {
  if (!(this instanceof Workbook)) return new Workbook();
  this.SheetNames = [];
  this.Sheets = {};
}

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

function saveExcel(setting: any, callback) {
  try {
    let { filename, sheetNames, ws } = setting;
    // 設定 sheet名稱
    let ws_name = filename;

    let wb: any = new Workbook();

    /* add worksheet to workbook */
    sheetNames.forEach((sheetName, index) => {
      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = ws[index];
    });

    let wbout = XLSXSTYLE.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });

    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      filename + ".xlsx"
    );

    setTimeout(() => {
      callback();
    }, 200);
  } catch (e) {
    console.log(e);

    setTimeout(() => {
      callback(String(e));
    }, 200);
  }
}

function checkColumn(columns, data) {
  let entries = Object.entries(columns),
    valid = true,
    errColumns = [];

  entries.forEach((item) => {
    if (data[item[1]] === undefined) {
      valid = false;
      errColumns.push(item[1]);
    }
  });

  return {
    valid: valid,
    errColumns: errColumns,
  };
}

export { readExcelFile, saveExcel, datenum, checkColumn };
