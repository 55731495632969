// primereact
import PrimeReact from "primereact/api";
import { locale, addLocale } from "primereact/api";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import "./styles/index.scss";
import "./styles/prime.scss";
import "./styles/rechart.scss";

PrimeReact.ripple = true;
PrimeReact.autoZIndex = false;
PrimeReact.zIndex = {
  modal: 1100, // dialog, sidebar
  overlay: 1150, // dropdown, overlaypanel
  menu: 1000, // overlay menus
  tooltip: 1100, // tooltip
  toast: 1200, // toast
};

addLocale("zh", {
  firstDayOfWeek: 0,
  dayNames: [
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
  ],
  dayNamesShort: ["日", "一", "二", "三", "四", "五", "六"],
  dayNamesMin: ["日", "一", "二", "三", "四", "五", "六"],
  monthNames: [
    "一月",
    "二月",
    "三月",
    "四月",
    "五月",
    "六月",
    "七月",
    "八月",
    "九月",
    "十月",
    "十一月",
    "十二月",
  ],
  monthNamesShort: [
    "一",
    "二",
    "三",
    "四",
    "五",
    "六",
    "七",
    "八",
    "九",
    "十",
    "十一",
    "十二",
  ],
  dateFormat: "yy/mm/dd",
  today: "今天",
  clear: "清除",
});
locale("zh");

export default function Wrap(props) {
  return <>{props.children}</>;
}
