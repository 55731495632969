import instance from "./interceptor";

import { system_api } from "../../service/system";

function request(url, params, options, method) {
  return new Promise((resolve, reject) => {
    let data = {};
    if (method === "get") data = { params };
    if (method === "post") data = { data: params };

    instance({
      url,
      method,
      ...options,
      ...data,
    })
      .then((res) => {
        setTimeout(() => {
          if (res) {
            if (res.Response) {
              if (!res.message) res.message = "資訊異常，請聯繫維修人員";
              resolve(res);
            } else {
              resolve({ Response: -1, data: res, message: "no Response" });
            }
          } else {
            resolve({ Response: -1, message: "無回應" });
          }
        }, system_api.delay);
      })
      .catch((error) => {
        let { code, response } = error;
        console.log(code);
        setTimeout(() => {
          let Response = -1,
            status = "-",
            message = "";
          if (response && response.status && response.status === 401) {
            Response = 401;
            message = "超過使用時限，請重新登入";
          } else if (response && response.status && response.status === 404) {
            Response = 404;
            message = "無此 API";
          } else if (code === "ECONNABORTED") {
            message = "連線超時，請檢查網路是否正常";
          } else if (code === "ERR_NETWORK") {
            message = "無法連線，請檢查網路是否正常";
          } else if (code === "ERR_CANCELED") {
            // message = "API 取消";
            return;
          } else {
            message = "資訊異常，請聯繫維修人員";
          }

          if (response && response.status) {
            status = response.status;
          }

          resolve({
            Response: Response,
            status: status,
            time: "-",
            message: message,
          });
        }, system_api.delay);
      })
      .finally(() => {});
  });
}

function get(url, params, options) {
  return request(url, params, options, "get");
}

function post(url, params, options) {
  return request(url, params, options, "post");
}

export default {
  get,
  post,
};
