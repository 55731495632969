import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
// import { EmployeeAPI } from "../../axios/api";
import { useAbortedEffect } from "../../components/hooks";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  FormInputtext,
  FormInputpassword,
} from "../../components/formElement/index";
import { formatResData, checkFormUpdate } from "../../service/common";

import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import { BreadCrumb } from "primereact/breadcrumb";

export default function EmployeeDetail(props) {
  const route_params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const crumbItem = [
    {
      label: "員工資料",
      command: () => navigate("/back/employee"),
    },
    { label: "員工明細" },
  ];
  const [reload, setReload] = useState({
    list: true,
  });
  // 明細資料
  const [apiData, setApiData] = useState({}); // 表單欄位預設值
  const defaultValues = {
    username: "",
    name: "",
    email_address: "",
    phone: "",
    original_start_date: "",
    department: "",
    business_function_lvl1: "",
    business_function_lvl2: "",
    business_function_lvl3: "",
    actual_mgrid: "",
    actual_supervisor_name: "",
    mgrid: "",
    supervisor_name: "",
    matrix_mgrid: "",
    location: "",
    cost_centre: "",
    password: "",
  };
  // 表單欄位驗證條件
  const schema = yup
    .object({
      // username: yup.string().required("必填"),
      // name: yup.string().required("必填"),
      // email_address: yup.string().required("必填"),
      // phone: yup.string().required("必填"),
      // original_start_date: yup.string().required("必填"),
      // department: yup.string().required("必填"),
      // business_function_lvl1: yup.string().required("必填"),
      // business_function_lvl2: yup.string().required("必填"),
      // business_function_lvl3: yup.string().required("必填"),
      // actual_mgrid: yup.string().required("必填"),
      // actual_supervisor_name: yup.string().required("必填"),
      // mgrid: yup.string().required("必填"),
      // supervisor_name: yup.string().required("必填"),
      // matrix_mgrid: yup.string().required("必填"),
      // location: yup.string().required("必填"),
      // cost_centre: yup.string().required("必填"),
      password: yup.string().required("必填"),
    })
    .required();
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  useAbortedEffect(
    (signal) => {
      if (!reload.list || route_params.id === "add") {
        return;
      }

      dispatch(setProgress(true));

      let params = {
        username: route_params.id,
      };

      // EmployeeAPI.getMemberDetails({ data: params, options: { signal } }).then(
      //   (result) => {
      //     let { Response, data, message } = result;

      //     if (Response === 1 && data) {
      //       setApiData(data);

      //       let temp = formatResData(defaultValues, data);
      //       reset(temp);
      //       setReload((state) => ({
      //         ...state,
      //         list: false,
      //       }));
      //     } else {
      //       dispatch(
      //         setToast({
      //           severity: "error",
      //           summary: message,
      //           detail: "",
      //         })
      //       );
      //     }

      //     dispatch(setProgress(false));
      //   }
      // );
    },
    [reload]
  );

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    // 顯示 loading
    dispatch(setProgress(true));

    let jsonData = {
      username: submitData.username,
      password: submitData.password,
    };

    if (!checkFormUpdate(jsonData, apiData)) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      return;
    }

    // EmployeeAPI.updateMemberDetailData({ data: jsonData }).then((result) => {
    //   let { Response, message } = result;

    //   if (Response === 1) {
    //     dispatch(
    //       setToast({
    //         severity: "success",
    //         summary: "修改成功",
    //         detail: "",
    //       })
    //     );
    //     // setReload((state) => ({ ...state, list: true }));
    //     navigate("/back/employee");
    //   } else {
    //     dispatch(
    //       setToast({
    //         severity: "error",
    //         summary: message ?? "修改失敗",
    //         detail: "",
    //       })
    //     );
    //     dispatch(setProgress(false));
    //   }
    // });
  };

  return (
    <>
      <BreadCrumb
        className="mb-3 border-none"
        model={crumbItem}
        home={{
          icon: "pi pi-home",
        }}
      />

      <h2 className="my-4 ml-4">員工明細</h2>

      <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid">
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="username"
            className={classNames(
              { "p-error": errors.username },
              "col-2 text-right mb-0"
            )}
          >
            員工編號(ID)
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputtext
              setting={setting}
              data={{ name: "username", disabled: true }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="name"
            className={classNames(
              { "p-error": errors.name },
              "col-2 text-right mb-0"
            )}
          >
            姓名
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputtext
              setting={setting}
              data={{ name: "name", disabled: true }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="email_address"
            className={classNames(
              { "p-error": errors.email_address },
              "col-2 text-right mb-0"
            )}
          >
            Email信箱地址
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputtext
              setting={setting}
              data={{ name: "email_address", disabled: true }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="original_start_date"
            className={classNames(
              { "p-error": errors.original_start_date },
              "col-2 text-right mb-0"
            )}
          >
            入職年份
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputtext
              setting={setting}
              data={{ name: "original_start_date", disabled: true }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="department"
            className={classNames(
              { "p-error": errors.department },
              "col-2 text-right mb-0"
            )}
          >
            部門
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputtext
              setting={setting}
              data={{ name: "department", disabled: true }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="business_function_lvl1"
            className={classNames(
              { "p-error": errors.business_function_lvl1 },
              "col-2 text-right mb-0"
            )}
          >
            Global Business Function-Lv1 1
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputtext
              setting={setting}
              data={{ name: "business_function_lvl1", disabled: true }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="business_function_lvl2"
            className={classNames(
              { "p-error": errors.business_function_lvl2 },
              "col-2 text-right mb-0"
            )}
          >
            Global Business Function-Lv1 2
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputtext
              setting={setting}
              data={{ name: "business_function_lvl2", disabled: true }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="business_function_lvl3"
            className={classNames(
              { "p-error": errors.business_function_lvl3 },
              "col-2 text-right mb-0"
            )}
          >
            Global Business Function-Lv1 3
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputtext
              setting={setting}
              data={{ name: "business_function_lvl3", disabled: true }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="actual_mgrid"
            className={classNames(
              { "p-error": errors.actual_mgrid },
              "col-2 text-right mb-0"
            )}
          >
            實際主管員工編號
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputtext
              setting={setting}
              data={{ name: "actual_mgrid", disabled: true }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="actual_supervisor_name"
            className={classNames(
              { "p-error": errors.actual_supervisor_name },
              "col-2 text-right mb-0"
            )}
          >
            實際主管姓名
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputtext
              setting={setting}
              data={{ name: "actual_supervisor_name", disabled: true }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="mgrid"
            className={classNames(
              { "p-error": errors.mgrid },
              "col-2 text-right mb-0"
            )}
          >
            直屬主管員工編號
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputtext
              setting={setting}
              data={{ name: "mgrid", disabled: true }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="supervisor_name"
            className={classNames(
              { "p-error": errors.supervisor_name },
              "col-2 text-right mb-0"
            )}
          >
            直屬主管姓名
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputtext
              setting={setting}
              data={{ name: "supervisor_name", disabled: true }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="matrix_mgrid"
            className={classNames(
              { "p-error": errors.matrix_mgrid },
              "col-2 text-right mb-0"
            )}
          >
            矩陣主管員工編號
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputtext
              setting={setting}
              data={{ name: "matrix_mgrid", disabled: true }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="location"
            className={classNames(
              { "p-error": errors.location },
              "col-2 text-right mb-0"
            )}
          >
            所在大樓
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputtext
              setting={setting}
              data={{ name: "location", disabled: true }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="cost_centre"
            className={classNames(
              { "p-error": errors.cost_centre },
              "col-2 text-right mb-0"
            )}
          >
            成本中心
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputtext
              setting={setting}
              data={{ name: "cost_centre", disabled: true }}
            />
          </div>
        </div>
        <div className="flex align-items-center field col-12">
          <label
            htmlFor="password"
            className={classNames(
              { "p-error": errors.password },
              "col-2 relative text-right mb-0"
            )}
          >
            密碼
            {/* <span className="absolute right-0 top-100 white-space-nowrap text-orange-400 pr-2">
              登入密碼用
            </span> */}
          </label>
          <div className="col-10 lg:col-5 md:col-8">
            <FormInputpassword setting={setting} data={{ name: "password" }} />
          </div>
        </div>

        <div className="field col-12 text-right">
          <Button
            type="button"
            label="取消"
            className="p-button-secondary p-button-outlined ml-auto px-3"
            onClick={() => navigate("/back/employee")}
          />
          <Button type="submit" label="確認" className="ml-2 px-3" />
        </div>
      </form>
    </>
  );
}
