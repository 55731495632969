import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

export default function FormInputtext(props) {
  const {
    className,
    setting: {
      Controller,
      control,
      setValue,
      trigger,
      isSubmitted,
      isEdit = true,
    },
    data: {
      uiClassName,
      name,
      maxlength,
      placeholder = "",
      disabled = false,
      saveFun = () => {},
    },
  } = props;

  const onChange = (e, field) => {
    const {
      target: { value },
    } = e;
    setValue(name, value);

    // 因為是客製化的 function，設定欄位值後(setValue)，需要觸發(trigger) hookform，才會被驗證
    if (isSubmitted) {
      trigger();
    } else {
      trigger(name);
    }
  };

  return (
    <>
      <Controller
        key={name}
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <div
            className={classNames(
              "flex align-items-center flex-auto",
              className
            )}
          >
            <InputText
              id={field.name}
              className={classNames(
                { "p-invalid": fieldState.invalid },
                "w-full",
                uiClassName
              )}
              {...field}
              value={field.value ? field.value : ""}
              onChange={(e) => onChange(e, field)}
              onBlur={saveFun}
              maxLength={maxlength}
              placeholder={placeholder}
              disabled={!isEdit || disabled}
            />
          </div>
        )}
      />
    </>
  );
}
