import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../redux/slice/system";
import { setUser } from "../redux/slice/user";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
// import { CommonAPI } from "../axios/api";

import LoginCss from "../styles/components/login.module.scss";

import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function Login(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // 用於控制表單刷新
  const [formKey, setFormKey] = useState(new Date());
  // 表單欄位預設值
  const defaultValues = {
    username: "test",
    password: "test",
  };
  // 表單欄位驗證條件
  const schema = yup
    .object({
      username: yup.string().required("必填"),
      password: yup.string().required("必填"),
    })
    .required();
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    // 顯示 loading
    dispatch(setProgress(true));

    dispatch(
      setUser({
        username: "test",
        now: moment().format("YYYY/MM/DD HH:mm:ss"),
      })
    );
    navigate("/back/pressure");
    dispatch(setProgress(false));

    // CommonAPI.login({ data: submitData }).then((result) => {
    //   let { Response, type, roles } = result;
    //   // 依照不同的回傳狀態，顯示提示訊息
    //   if (
    //     Response === 1 &&
    //     (type === 9 || roles.indexOf("ROLE_ADMIN") !== -1)
    //   ) {
    //     result.now = moment().format("YYYY/MM/DD HH:mm:ss");

    //     reset();
    //     setFormKey(new Date());
    //     dispatch(setUser(result));

    //     navigate("/back/employee");
    //   } else if (
    //     Response === 1 &&
    //     type !== 9 &&
    //     roles.indexOf("ROLE_ADMIN") === -1
    //   ) {
    //     dispatch(
    //       setToast({
    //         severity: "error",
    //         summary: "登入失敗",
    //         detail: "無權限",
    //       })
    //     );
    //   } else {
    //     dispatch(
    //       setToast({
    //         severity: "error",
    //         summary: "登入失敗",
    //         detail: "帳密錯誤",
    //       })
    //     );
    //   }

    //   dispatch(setProgress(false));
    // });
  };

  // 若欄位驗證錯誤，顯示錯誤訊息
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <>
      <div
        className={`${LoginCss["login-wrap"]} flex flex-column align-items-center justify-content-center w-full h-full`}
      >
        <Card
          className={`${LoginCss["login-inner-wrap"]} w-10 sm:w-6 max-w-30rem text-2xl px-3`}
        >
          <h1 className="text-center mt-0 mb-0">壓力測試 (API)</h1>

          <div className="mb-8"></div>

          <form
            key={formKey}
            onSubmit={handleSubmit(onSubmit)}
            className="p-fluid"
          >
            <div className="field">
              <Controller
                name="username"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    placeholder="輸入帳號"
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
            </div>
            <div className="field">
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState }) => (
                  <Password
                    id={field.name}
                    {...field}
                    toggleMask
                    feedback={false}
                    placeholder="輸入密碼"
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
            </div>

            <Button
              type="submit"
              label="登入"
              className="p-button-primary mt-3"
            />
          </form>
        </Card>
      </div>
    </>
  );
}
